<template>
  <section>
    <div class="divider" />
    <h2>Support page will be here</h2>
    <SimpleButton label="Open modal" @click="dialog = true" />
    <Dialog v-model="dialog" title="Some title">
      <h3>From outer</h3>
      <SimpleButton label="Close modal" @click="dialog = false" />
    </Dialog>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import SimpleButton from '@/components/ui/SimpleButton'
import Dialog from '@/components/Dialog'

const dialog = ref(false)
</script>

<style scoped>
.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
  border: 0;
  background: rgba(201, 204, 206, 0.38);
}
</style>
