<template>
  <Transition name="overlay">
    <div class="overlay" v-show="dialog" @click="outerClick">
      <Transition name="dialog">
        <div v-show="dialog" class="dialog-inner">
          <slot name="header">
            <h2 class="dialog-title">{{title}}</h2>
          </slot>
          <slot />
        </div>
      </Transition>
    </div>
  </Transition>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  title: {
    type: String,
    default: ''
  }
})

const emit = defineEmits({
  'update:modelValue': _ => true
})

const dialog = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const outerClick = (e) => {
  if (!e.target.closest('.dialog-inner')) dialog.value = false
}
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.dialog-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  min-height: 100px;
  border-radius: 10px;
  background: white;
  padding: 70px;
}

.dialog-title {
  margin: 0 0 40px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 48px;
  color: black;
}

/* TRANSITIONS */

.overlay-enter-active,
.overlay-leave-active {
  transition: all 0.3s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.dialog-enter-active,
.dialog-leave-active {
  transition: transform 0.4s ease;
}

.dialog-enter-from,
.dialog-leave-to {
  transform: translate(-50%, -70%);
}

</style>
